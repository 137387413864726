import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BlogPostCard from "../blog-post-card";

const BlogPostsSection = ({ data }) => {
  return (
    <div className="flex items-center flex-col justify-center mb-12">

      <div className="max-w-[1440px] ml-auto mr-auto w-full">
        <h2 className="margin-correction text-fix capitalize px-0 lg:px-8 font-montserrat text-dark-blue font-bold !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">{data.heading}</h2>
      </div>
      <div className="max-w-none ml-auto mr-auto w-full">      
        {/* featured Blog */}
        {data.featuredBlog && 
        <Link className="flex items-center flex-col mt-12 no-underline" to={data.featuredBlog.path}>
          {data.featuredBlog.image.gatsbyImageData && <GatsbyImage alt={data.featuredBlog.image.title} className="w-full h-full max-h-[404px]" image={getImage(data.featuredBlog.image.gatsbyImageData)}/>}
          {data.featuredBlog.videoImagePlaceHolder?.gatsbyImageData && <GatsbyImage alt={data.featuredBlog.videoImagePlaceHolder?.title} className="w-full h-full max-h-[404px]" image={getImage(data.featuredBlog.videoImagePlaceHolder?.gatsbyImageData)}/>}
          
        </Link>}
        {data.featuredBlog && 
        <Link className="bg-dark-blue max-w-none ml-auto mr-auto no-underline flex items-start flex-col w-full md:p-8 blue_box_margin-correction" to={data.featuredBlog.path}>
          <div className="ml-auto mr-auto max-w-[1440px] w-full my-4 flex items-center justify-center md:justify-start">
            <button className="bg-dark-blue border-2 border-white rounded-[3rem] py-1 px-4">
              <span className="text-white capitalize font-poppins !text-[16px] font-medium">{data.featuredBlog.category}</span>
            </button>
                  
            <div className="ml-4 flex flex-row items-center">
              <svg className="shrink-0" fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path clipRule="evenodd" d="M7 1.07692C3.72878 1.07692 1.07692 3.72878 1.07692 7C1.07692 10.2712 3.72878 12.9231 7 12.9231C10.2712 12.9231 12.9231 10.2712 12.9231 7C12.9231 3.72878 10.2712 1.07692 7 1.07692ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z" fill="white" fillRule="evenodd"/>
                <path clipRule="evenodd" d="M7 2.69231C7.29738 2.69231 7.53846 2.93339 7.53846 3.23077V6.46154H10.7692C11.0666 6.46154 11.3077 6.70262 11.3077 7C11.3077 7.29738 11.0666 7.53846 10.7692 7.53846H7C6.70262 7.53846 6.46154 7.29738 6.46154 7V3.23077C6.46154 2.93339 6.70262 2.69231 7 2.69231Z" fill="white" fillRule="evenodd"/>
              </svg>

              <p className="ml-2 text-white capitalize font-poppins mb-0 !text-[16px] font-normal">{data.featuredBlog.readTime} min read</p>
            </div>
                  
          </div>

          <Link className="w-full ml-auto mr-auto max-w-[1440px] no-underline" to={data.featuredBlog.path}><h3 className="text-fix text-[#FFFFFF] hover:text-orange font-bold font-montserrat !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">{data.featuredBlog.title}</h3></Link>
          <p className="w-full ml-auto mr-auto max-w-[1440px] text-paragraph-fix text-white mt-6 font-poppins font-normal !text-[16px] leading-[150%]">{data.featuredBlog.description.description}</p>
          <p className="w-full ml-auto mr-auto max-w-[1440px] text-paragraph-fix text-white mt-6 font-poppins font-normal !text-[16px] leading-[105%]">by {data.featuredBlog.author}&nbsp;|&nbsp;{data.featuredBlog.date}</p>
        </Link>}
        
        {/* additional blogs */}
        <div className="max-w-[1440px] margin-correction ml-auto mr-auto grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-16 pt-8">
          {data.additionalBlogs.map((blog, index) => {
            return (
              <BlogPostCard data={blog} key={index}/>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogPostsSection;