import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import RichText from "../../rich-text";
import { Link } from "gatsby";

const OuterWrapper = styled.div`
  /* width: 95%; */
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  background-color: ${({ bgColor }) => bgColor};

  button {
    color: ${({ buttonTextColor }) => buttonTextColor === (null || "white") ? "white" : "var(--orange)"};
    background-color: ${({ buttonColor }) => buttonColor === (null || "orange") ? "var(--orange)" : "white"};
  }
`;

//image on either side css

const InnerWrapperCol = styled.div`
  padding: 4rem 3rem;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 5rem;

  @media only screen and (max-width: 768px){
    flex-direction: column;
    column-gap: 0;
    row-gap: 3rem;
  }
`;

const ColImageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;

  h1 {
    color: ${({ h1Color }) => h1Color};
    text-align: left;
    margin-bottom: 2rem
  }

  h2{
    color: ${({ h2Color }) => h2Color};
    text-align: left;
    margin-bottom: 2rem;
  }

  p{
     color: ${({ richColor }) => richColor};
     text-align: left;
     margin-bottom: 2rem;
  }

  @media only screen and (max-width: 768px){
    width: 100%;
  }
`;

//background image css

const InnerWrapperBg = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
`;

const BgImageText = styled.div`
  max-width: 989px;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 1;

  h1 {
    color: ${({ h1Color }) => h1Color};
    text-align: center;
    margin-bottom: 2rem
  }

  h2{
    color: ${({ h2Color }) => h2Color};
    text-align: center;
    margin-bottom: 2rem;
  }

  p{
     color: ${({ richColor }) => richColor};
     text-align: center;
     margin-bottom: 2rem;
  }

  @media only screen and (max-width: 768px){
    padding: 5rem 3rem;
  }
`;

const ExpertiseContent = ({ data }) => {
  const { 
    header, headerTextColor,
    subHeader, subHeaderTextColor,
    richText, richTextColor, 
    button, buttonText, buttonUrl, buttonColor, buttonTextColor,
    useImage, columnImagePlacement, backgroundImage, columnImage,
    backgroundColor, 
  } = data;

  const colors = {
    "black": "#000000",
    "blue": " var(--blue)",
    "none": "transparent",
    "orange": "var(--orange)",
    "white": "#FFFFFF",
  };

  const h1Color = colors[headerTextColor];
  const h2Color = colors[subHeaderTextColor];
  const richColor = colors[richTextColor];
  const bgColor = colors[backgroundColor];

  return (
    <OuterWrapper bgColor={bgColor} buttonColor={buttonColor} buttonTextColor={buttonTextColor}>

      {((useImage === "column") && (columnImagePlacement === false)) &&
      <InnerWrapperCol bgColor={bgColor}> 
        <ColImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[20px] md:text-[24px]">{subHeader}</h2>}
          <RichText embeddedAssetAlignment={"left"} json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </ColImageText>
        <GatsbyImage alt={columnImage.title} className="w-full md:w-[50%] h-full flex-shrink-0" image={getImage(columnImage.gatsbyImageData)}/>
      </InnerWrapperCol>
      }

      {((useImage === "column") && (columnImagePlacement)) &&
      <InnerWrapperCol bgColor={bgColor}>
        <GatsbyImage alt={columnImage.title} className="w-full md:w-[50%] h-full flex-shrink-0" image={getImage(columnImage.gatsbyImageData)}/>
        <ColImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[20px] md:text-[24px]">{subHeader}</h2>}
          <RichText json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </ColImageText>
      </InnerWrapperCol>
      }

      {useImage === "background" && header &&
      <InnerWrapperBg>
        <GatsbyImage alt={backgroundImage.title} className="!absolute top-0 left-0 w-full h-full z-[-1]" image={getImage(backgroundImage.gatsbyImageData)}/>
        <BgImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{subHeader}</h2>}
          <RichText json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </BgImageText>
      </InnerWrapperBg>}

      {useImage === "background" && !header &&
      <InnerWrapperBg>
        <GatsbyImage alt={backgroundImage.title} className="w-full h-full" image={getImage(backgroundImage.gatsbyImageData)}/>
      </InnerWrapperBg>}

      {/* right col image */}
      {useImage === "both" && (columnImagePlacement === false) &&
      <InnerWrapperCol>
        <GatsbyImage alt={backgroundImage.title} className="!absolute top-0 left-0 w-full h-full z-[-1]" image={getImage(backgroundImage.gatsbyImageData)}/>
        <ColImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{subHeader}</h2>}
          <RichText json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </ColImageText>
        <GatsbyImage alt={columnImage.title} className="w-full md:w-[50%] h-full flex-shrink-0" image={getImage(columnImage.gatsbyImageData)}/>
      </InnerWrapperCol>}

      {/* left col image */}
      {useImage === "both" && columnImagePlacement &&
      <InnerWrapperCol>
        <GatsbyImage alt={backgroundImage.title} className="!absolute top-0 left-0 w-full h-full z-[-1]" image={getImage(backgroundImage.gatsbyImageData)}/>
        <ColImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{subHeader}</h2>}
          <RichText json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </ColImageText>
        <GatsbyImage alt={columnImage.title} className="w-full md:w-[50%] h-full flex-shrink-0" image={getImage(columnImage.gatsbyImageData)}/>
      </InnerWrapperCol>}

      {useImage === "none" &&
      <InnerWrapperBg  bgColor={bgColor}>
        <BgImageText h1Color={h1Color} h2Color={h2Color} richColor={richColor}>
          <h1 className="font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{header}</h1>
          {subHeader && <h2 className="font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{subHeader}</h2>}
          <RichText json={JSON.parse(richText.raw)} references={richText.references} />
          {button &&
            <Link to={buttonUrl}><button className='hire_button hire_button--desktop'>{buttonText}</button></Link>}
        </BgImageText>
      </InnerWrapperBg>}
      
    </OuterWrapper>
  );
};

export default ExpertiseContent;