import React from "react";
import styled from "styled-components";

const ServiceListingWrapper = styled.div`
  padding: 5rem 3rem;
  max-width: 1440px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const GirdLayout = styled.ul`
  display: grid;
  grid-template-columns: repeat(${({ numOfCols }) => numOfCols}, 1fr);
  column-gap: 3rem;
  place-items: baseline;
  list-style: disc;
  list-style-position: outside;

  @media only screen and (max-width: 1024px){
    grid-template-columns: 1fr;
    column-gap: 0rem;
  }
`;

const ServicesListing = ({ data }) => {
  const { header, bulletPoints, numOfCols } = data;

  return (
    <ServiceListingWrapper>
      <div className="w-full text-center mb-12 md:mb-16">
        <h2 className="font-montserrat text-dark-blue font-bold text-[18px] sm:text-[24px] md:text-[36px]">{header}</h2>
      </div>
      <GirdLayout numOfCols={numOfCols}>
        {bulletPoints.map((listedItem, index) => {
          return (
            <li className="text-dark-blue font-medium font-montserrat text-[18px] sm:text-[20px] md:text-[24px]" key={index}>{listedItem}</li>
          );
        })} 
      </GirdLayout>

    </ServiceListingWrapper>
  );
};

export default ServicesListing;