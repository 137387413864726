import React, { useEffect, useState } from "react";
import Layout from "../layouts";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import { BlogPostsSection } from "../components/blogs/";
import { ServicesContent, ServicesListing } from "../components/services/";
import { ExpertiseContent } from "../components/expertise";
import { HireUs } from "../components/hire-us";
import Background from "../components/background";
import Toolbar from "@mui/material/Toolbar";
import { Pagination } from "@mui/material";
import BlogPostCard from "../components/blogs/blog-post-card";
// import AlgoliaSearch from "../components/algolia";
import { NewsletterForm } from "../components/newsletter-form";

const sections = {
  BlogPostsSection: BlogPostsSection,
  HireUs: HireUs,
  ImageWOrWOText: ExpertiseContent,
  ServicesContent: ServicesContent,
  ServicesListing: ServicesListing,
};

const TemplateStandard = (pageContext) => {
  const page_data = pageContext.data;
  const page_references = page_data.contentfulTemplateStandard.sections;
  const blog_references = page_data.allContentfulTemplateBlogPost.nodes;
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [startingIndex, setStartingIndex] = useState(1);
  const total_blogs = blog_references.length;
  const pages = Math.ceil(total_blogs / 6);
  const [shownBlogs, setShownBlogs] = useState(blog_references.slice(0, 6));

  useEffect(() => {
    setShownBlogs(returnBlogs(startingIndex, 6));
  }, [startingIndex]);

  function returnBlogs(currentIndex, stepSize) {
    let blog_array = [];
    let counter = 0;
    for (let i = ((currentIndex - 1) * stepSize); i < total_blogs; i++) {
      blog_array.push(blog_references[i]);
      counter++;

      if (counter >= 6) {
        break;
      }
    }
    return blog_array;
  }

  return (
    <Layout>
      <SEO
        description={page_data.contentfulTemplateStandard.description.description}
        title={page_data.contentfulTemplateStandard.title}
      />
      {pageContext.pageContext.name.includes("all") &&
      <>
        <div className="flex items-center flex-col relative z-0 mb-12">
          <div className="max-w-[850px] ml-8 mr-8 md:ml-auto md:mr-auto">
            <h1 className="font-montserrat text-orange text-center !text-[18px] uppercase">Blog</h1>
            <h3 className="font-montserrat text-dark-blue text-center font-bold !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">Keep up with what’s going on around you.</h3>

            {/* form */}
            <div className="mt-12 flex justify-center items-center">
              <div className="max-w-[472px] w-full">
                <p className="font-montserrat font-medium leading-6 !text-[16px] text-center">Stay informed on our latest updates about marketing trends, best practices, and case studies.</p>
                <NewsletterForm/>
              </div>
          
            </div>
          </div>
          <Background/>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-full max-w-[850px] ml-auto mr-auto">
            <Toolbar
              className="mb-12"
              component="nav"
              sx={{ alignItems: "center", justifyContent: "space-between", overflowX: "auto" }}
              variant="dense"
            >
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/all">All</Link>
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/ecommerce">Ecommerce</Link>
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/growth">Growth</Link>
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/digital">Digital</Link>
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/influencer">Influencer</Link>
              <Link activeClassName='active-link' className="sub_nav__margin text-dark-blue hover:text-orange no-underline capitalize font-montserrat font-bold text-[16px] leading-[159%]" onClick={() => setShow(false)} to="/category/creative">Creative</Link>
            </Toolbar>
          </div>
        </div>
        {/* <div className="flex mb-6 items-center justify-center">
          <AlgoliaSearch/>
        </div> */}
      </>}
      {page_references?.map((ref, index) => {
        const Section = sections[ref.internal.type.replace("ContentfulSection", "")];
        return (
          <Section
            data={ref}
            key={index}
          />
        );
      })}
      {pageContext.pageContext.name.includes("all") && <>
        <div className="max-w-[1440px] ml-auto mr-auto w-full">
          <h2 className="margin-correction text-fix capitalize px-0 lg:px-8 font-montserrat text-dark-blue font-bold !text-[18px] sm:!text-[24px] md:!text-[36px] leading-[105%]">All Articles</h2>
        </div>
        <div className="w-full">
          <div className="max-w-[1440px] margin-correction ml-auto mr-auto grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-16 pt-8">
            {shownBlogs.map((blog, index) => {
              return (
                <BlogPostCard data={blog} key={index}/>
              );
            })

            }
          </div>
          {pages > 1 &&
        <div className="mt-8 mb-12 flex justify-center items-center">
          <Pagination 
            count={pages}
            onChange={(event, pageNumber) => {
              setStartingIndex(pageNumber);
            }}
          /></div>}
        </div></>}
    </Layout>
  );
};

export default TemplateStandard;

export const query = graphql`
query TemplateStandardQuery($path: String) {
  contentfulTemplateStandard(path: {eq: $path}) {
    title
    path
    description {
      description
    }
    sections {
      ... on ContentfulSectionHireUs {
        internal {
          type
        }
        header
        subHeader
        buttonText
      }
      ... on ContentfulSectionServicesListing {
        internal {
          type
        }
        bulletPoints
        numOfCols
        header
      }
      ... on ContentfulSectionImageWOrWOText {
        internal {
          type
        }
        header
        headerTextColor
        subHeader
        subHeaderTextColor
        richText {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
              title
            }
          }
        }
        richTextColor
        button
        buttonColor
        buttonTextColor
        buttonText
        buttonUrl
        columnImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          title
        }
        backgroundImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          title
        }
        useImage
        backgroundColor
        columnImagePlacement
      }
      ... on ContentfulSectionServicesContent {
        internal {
          type
        }
        header
        headerTextColor
        subHeader
        subHeaderTextColor
        richText {
          raw
          references {
            contentful_id
            gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
            title
            
          }
        }
        richTextColor
        button
        buttonColor
        buttonTextColor
        buttonText
        buttonUrl
        columnImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          title
        }
        backgroundImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          title
        }
        useImage
        backgroundColor
        columnImagePlacement
      }
      ... on ContentfulSectionBlogPostsSection {
        internal {
          type
        }
        heading
        featuredBlog {
          title
          path
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          videoImagePlaceHolder {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          description {
            description
          }
          author
          body {
            raw
          }
          date
          readTime
          category
        }
        additionalBlogs {
          title
          path
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          videoImagePlaceHolder {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            title
          }
          description {
            description
          }
          author
          body {
            raw
          }
          date
          category
          readTime
        }
      }
    }
  }
  allContentfulTemplateBlogPost(
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      title
      category
      author
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        title
      }
      videoImagePlaceHolder {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        title
      }
      description {
        description
      }
      date(formatString: "MMM DD, YYYY")
      path
      readTime
    }
    totalCount
  }
}
`;
