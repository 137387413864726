import React from "react";
import { Link } from "gatsby";

const HireUs = ({ data }) => {
  const { header, subHeader, buttonText } = data;
  return (
    <div className="py-20 px-12 bg-dark-blue w-full text-center">
      <h2 className="text-white font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{header}</h2>
      {subHeader && <h3 className="text-white font-montserrat font-bold text-[24px] sm:text-[30px] md:text-[48px]">{subHeader}</h3>}
      <Link to="/contact-us"><button className='mt-12 hire_button hire_button--desktop'>{buttonText}</button></Link>
    </div>
  );
};

export default HireUs;